<template>
  <div>
    <div>
      <el-form
        ref="checkForm"
        :model="ruleForm"
        :rules="rules"
        class="rule-form"
        label-width="90px"
      >
        <div class="dialog-box">
          <div class="flex">
            <el-form-item class="flex-1" label="物品类别" prop="goodsCode">
              <el-cascader style="width: 175px"
                v-model="ruleForm.goodsCode"
                :options="options" 
                :show-all-levels="false" 
                :props="{
                  label: 'goodsType',
                  value: 'goodsType',
                  children: 'children',
                  emitPath: false
                }"
                placeholder="请选择">
              </el-cascader>
            </el-form-item>
            <el-form-item
              class="flex-1"
              label="物品名称"
              prop="goodsName"
            >
              <el-input style="width: 175px"
                v-model="ruleForm.goodsName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item class="flex-1" label="规格" prop="specifications">
              <el-input style="width: 175px"
                v-model="ruleForm.specifications"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item class="flex-1" label="单位" prop="unit">
              <el-input style="width: 175px" v-model="ruleForm.unit" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item class="flex-1" label="型号" prop="goodsType">
              <el-input style="width: 175px" 
                v-model="ruleForm.goodsType"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item class="flex-1" label="颜色" prop="color">
              <el-input style="width: 175px"
                v-model="ruleForm.color"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item class="flex-1" label="尺寸" prop="size">
              <el-input style="width: 175px" v-model="ruleForm.size" placeholder="请输入"></el-input>
            </el-form-item>
            <div>
              <el-button @click="submitForm()" type="primary" size="small">确定</el-button>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div style="max-height: 200px;overflow:auto;">
      <el-table
        :data="details"
        style="width: 100%"
        border
        ref="multipleTable"
        :row-key="getRowKeys"
        :header-cell-style="{ backgroundColor: '#fafafa',padding:'6px 0' }"
        :cell-style="{ padding:'6px 0' }"
        @selection-change="handleSelectChange"
      >
        <el-table-column property="id" type="selection" width="50" />
        <el-table-column label="序号" width="50">
          <template #default="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column property="goodsCode" label="物品类别" />
        <el-table-column property="goodsName" label="物品名称" />
        <el-table-column property="specifications" label="规格" />
        <el-table-column property="unit" label="单位" />
        <el-table-column property="goodsType" label="型号" />
        <el-table-column property="color" label="颜色" />
        <el-table-column property="size" label="尺寸" />
        <el-table-column label="操作" width="95">
          <template #default="scope">
            <el-button @click="del(scope.$index)" type="text" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div
      class="drawer-footer flex flex-justify-between"
      style="width: 300px; margin: 30px auto 0"
    >
      <el-button @click="hide" size="small">取 消</el-button>
      <el-button @click="addSuccess" type="primary" size="small">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { getLogisticTypes } from "@/service/blc";

export default {
  components: {},
  data() {
    return {
      rules: {
        goodsCode: [{ required: "true", message: "请选择物品类别" }],
        goodsName: [{ required: "true", message: "请输入物品名称" }],
        specifications: [{ required: "true", message: "请输入规格" },],
        goodsType: [{ required: "true", message: "请输入型号" }],
      },
      options: [],
      ruleForm: {
        id: "",
        goodsCode: "", //物品类别Code
        goodsName: "", //物品名称
        specifications: "", //规格型号
        unit: "", //计量单位
        goodsType: "", //型号
        color: "", //颜色
        size: "", //尺寸（长宽高）组合
        number: 1, //数量
      },
      details: [],
      rows: [],
    };
  },
  computed: {},
  created() {
    this.getSelections();
  },
  methods: {
    async getSelections() {
      let res = await getLogisticTypes();
      if (res && res.content.length>0) {
        let list = res.content.map((item) => {
          return item.item;
        });
        console.log("list",list);
        list.forEach((e) => {
          if (e.children && e.children.length>0) {
            this.digui(e.children);
          } else {
            e.children = null;
          }
        });
        this.options = list;
        console.log("options",this.options,list)
      }
    },
    digui(list) {
      list.forEach((e)=>{
        if (e.children && e.children.length>0) {
          this.digui(e.children);
        } else {
          e.children = null;
        }
      })
    },
    getRowKeys(row) {
      return row.id;
    },
    //确定选中
    handleSelectChange(rows) {
        console.log(rows);
        //将选中赋值到回显和传参数组
        this.templateRadio = rows;
        this.rows = [];
        if (rows) {
          rows.forEach(row => {
            this.rows.push(row);
          });
        }
        this.rows = rows;
        console.log(this.rows);
    },
    toggleSelection() {
      if (this.details) {
        this.details.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row,true);
        });
      }
    },
    addSuccess() {
      this.$emit("addSuccess",this.rows);
      this.hide();
    },
    hide() {
      this.$emit("hide");
    },
    del(idx) {
      this.details.splice(idx,1);
    },
    submitForm() {
      this.$refs.checkForm.validate((valid) => {
        if (valid) {
          this.submit();
        }
      });
    },
    submit() {
      let obj = JSON.parse(JSON.stringify(this.ruleForm));
      obj.id = new Date().getTime();
      this.details.push(obj);
      this.toggleSelection();
      // console.log("this.ruleForm.id",this.ruleForm.id,this.details);
      this.ruleForm = {
        goodsCode: "", //物品类别Code
        goodsName: "", //物品名称
        specifications: "", //规格型号
        unit: "", //计量单位
        goodsType: "", //型号
        color: "", //颜色
        size: "", //尺寸（长宽高）组合
        number: 1, //数量
      };
    },
  },
};
</script>
<style lang="less">

</style>