<template>
  <div class="dialog">
    <div class="dialog-container">
      <el-form
        ref="checkForm"
        :model="ruleForm"
        :rules="rules"
        class="rule-form"
        label-width="135px"
      >
        <div class="dialog-box">
          <h3 class="db-head">寄件信息</h3>
          <div class="flex" v-if="waybill_num">
            <el-form-item class="flex-1" label="运单编号" prop="waybill_num">
              <el-input
                v-model="waybill_num"
                :disabled="!isEdit"
                placeholder="请输入运单编号"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item class="flex-1" label="仓库名称" prop="warehouseName">
              <el-input
                v-model="ruleForm.warehouseName"
                :disabled="!isEdit"
                placeholder="请输入仓库名称"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="flex-1"
              label="货品总件数"
              prop="totalCount"
            >
              <el-input-number 
                v-model="ruleForm.totalCount" 
                :disabled="!isEdit"
                controls-position="right" 
                placeholder="请输入货品总件数" 
                style="width: 200px" 
                :min="1" size="mini"
              ></el-input-number>
            </el-form-item>
            <el-form-item class="flex-1" label="送货日期" prop="deliveryDate">
                <el-date-picker style="width:200px;"
                    v-model="ruleForm.deliveryDate"
                    :disabled="!isEdit"
                    type="datetime"
                    placeholder="请选择送货日期"
                >
                </el-date-picker>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item class="flex-1" label="报关方式" prop="cebxsi">
              <el-select v-model="ruleForm.cebxsi" :disabled="!isEdit" placeholder="请选择报关方式" style="width:200px;">
                <el-option
                    v-for="item in [{id:0,name:'自理报关'},{id:1,name:'直接报关'},{id:2,name:'间接报关'}]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item class="flex-1" label="出口城市" prop="exportCity">
              <el-input
                v-model="ruleForm.exportCity"
                :disabled="!isEdit"
                placeholder="请输入出口城市"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item class="flex-1" label="拼单" prop="ifAcceptOrder">
              <el-checkbox v-model="ruleForm.ifAcceptOrder" :disabled="!isEdit" label="我接受拼单"></el-checkbox>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item
              class="flex-1"
              label="寄件（配货）方式"
              prop="mailingMethod"
            >
              <el-select v-model="ruleForm.mailingMethod" :disabled="!isEdit" placeholder="请选择寄件（配货）方式" style="width:200px;">
                <el-option
                    v-for="item in [{id:1,name:'上门提货'},{id:2,name:'自己送货'}]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item class="flex-1" label="运输方式" prop="transportCategory">
              <el-radio-group v-model="ruleForm.transportCategory" :disabled="!isEdit">
                <el-radio :label="0">城配</el-radio>
                <el-radio :label="1">物流</el-radio>
            </el-radio-group>
            </el-form-item>
            <div class="flex-1"></div>
          </div>
          <div class="flex">
            <el-form-item class="flex-1" label="付款方式" prop="paymentMethod">
              <el-select v-model="ruleForm.paymentMethod" :disabled="!isEdit" placeholder="请选择付款方式" style="width:200px;">
                <el-option
                    v-for="item in [{id:0,name:'月结'},{id:1,name:'现付'}]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item class="flex-1" label="备注" prop="remark">
              <el-input type="textarea"
                v-model="ruleForm.remark"
                :disabled="!isEdit"
                placeholder="请输入备注"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <div class="flex-1"></div>
          </div>
          <h3 class="db-head">交货方式</h3>
          <div class="flex">
            <el-form-item class="flex-1" label="安装方式" prop="installationMethod">
              <el-select v-model="ruleForm.installationMethod" :disabled="!isEdit" placeholder="请选择安装方式" style="width:200px;">
                <el-option
                    v-for="item in [{id:0,name:'自己安装'},{id:1,name:'预约安装'}]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item class="flex-1" label="送货方式" prop="deliveryMethod">
              <el-select v-model="ruleForm.deliveryMethod" :disabled="!isEdit" placeholder="请选择送货方式" style="width:200px;">
                <el-option
                    v-for="item in [{id:0,name:'无'},{id:1,name:'自提'},{id:2,name:'上门'}]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <div class="flex-1"></div>
          </div>
          <h3 class="db-head">收件人信息</h3>
          <div class="flex">
            <el-form-item
              class="flex-1"
              label="收件公司名称"
              prop="customerName"
            >
              <el-input
                v-model="ruleForm.customerName"
                :disabled="!isEdit"
                placeholder="请输入收件公司名称"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="flex-1"
              label="收件地址(目的地)"
              prop="customerAddress"
            >
              <el-input
                v-model="ruleForm.customerAddress"
                :disabled="!isEdit"
                placeholder="请输入收件地址(目的地)"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item
              class="flex-1"
              label="联系人姓名"
              prop="customerContacts"
            >
              <el-input
                v-model="ruleForm.customerContacts"
                :disabled="!isEdit"
                placeholder="请输入联系人姓名"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item class="flex-1" label="联系电话" prop="customerContactsPhone">
              <el-input
                v-model="ruleForm.customerContactsPhone"
                :disabled="!isEdit"
                placeholder="请输入联系电话"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </div>
          <h3 class="db-head">寄件人信息</h3>
          <div class="flex">
            <el-form-item
              class="flex-1"
              label="寄件公司名称"
              prop="enterpriseName"
            >
              <el-input
                v-model="ruleForm.enterpriseName"
                :disabled="!isEdit"
                placeholder="请输入寄件公司名称"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item class="flex-1" label="提货地址" prop="enterpriseAdress">
              <el-input
                v-model="ruleForm.enterpriseAdress"
                :disabled="!isEdit"
                placeholder="请输入提货地址"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item
              class="flex-1"
              label="寄件负责人"
              prop="enterpriseDirector"
            >
              <el-input
                v-model="ruleForm.enterpriseDirector"
                :disabled="!isEdit"
                placeholder="请输入"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="flex-1"
              label="负责人电话"
              prop="enterpriseDirectorMobile"
            >
              <el-input
                v-model="ruleForm.enterpriseDirectorMobile"
                :disabled="!isEdit"
                placeholder="请输入"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="dialog-table">
      <el-button
        v-if="isEdit || isNew"
        style="margin-bottom: 12px"
        type="primary"
        @click="addSon()"
      >添加物品</el-button>
      <el-table
        :data="ruleForm.details"
        style="width: 100%"
        border
        :header-cell-style="{backgroundColor: '#fafafa'}"
      >
        <el-table-column label="序号" width="50">
          <template #default="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column property="goodsCode" label="物品类别" />
        <el-table-column property="goodsName" label="物品名称" />
        <el-table-column property="specifications" label="规格" />
        <el-table-column property="unit" label="单位" />
        <el-table-column property="goodsType" label="型号" />
        <el-table-column property="color" label="颜色" />
        <el-table-column property="size" label="尺寸" />
        <el-table-column label="数量" width="160">
          <template #default="scope">
            <div v-if="isEdit">
              <el-input-number v-model="scope.row.number" controls-position="right" :min="1" size="mini"></el-input-number>
            </div>
            <div v-else>{{scope.row.number}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="95">
          <template #default="scope">
            <el-button v-if="isEdit" @click="del(scope.$index)" type="text" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="isEdit"
      class="drawer-footer flex flex-justify-between"
      style="width: 400px; margin: 30px auto 0"
    >
      <el-button @click="hide">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </div>
    <div v-else
      class="drawer-footer flex flex-center"
      style="width: 400px; margin: 30px auto 0"
    >
      <el-button type="primary" @click="hide">关 闭</el-button>
    </div>

    <el-dialog width="900px" title="添加物品信息" :visible.sync="visible" :append-to-body="true" :modal-append-to-body="false" :destroy-on-close="true">
      <EditLogisticsAdd @addSuccess="add_success" @hide="visible=false" />
    </el-dialog>
  </div>
</template>
<script>
import { createWaybill, getWaybill, queryOrderWaybill } from "@/service/sale"
import EditLogisticsAdd from "./EditLogisticsAdd"
import { phoneReg } from "@/utils";

export default {
  components: {
    EditLogisticsAdd
  },
  model: {
    prop: "orderId",
    event: "change",
  },
  props: ["orderId"],
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = phoneReg;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      rules: {
        warehouseName: [{ required: "true", message: "请选择仓库名称" }],
        totalCount: [{ required: "true", message: "请输入货品总计数" }],
        deliveryDate: [{ required: "true", message: "请输入送货日期" }],
        mailingMethod: [{ required: "true", message: "请选择寄件（配货）方式" }],
        transportCategory: [{ required: "true", message: "请输入运输方式" }],
        installationMethod: [{ required: "true", message: "请选择安装方式" }],
        customerName: [{ required: "true", message: "请输入收件公司名称" }],
        customerAddress: [{ required: "true", message: "请输入收件地址(目的地)" }],
        customerContacts: [{ required: "true", message: "请输入联系人姓名" }],
        customerContactsPhone: [{ validator: checkPhone, }],
        enterpriseName: [{ required: "true", message: "请输入寄件公司名称" }],
        enterpriseAdress: [{ required: "true", message: "请输入提货地址" }],
        enterpriseDirector: [{ required: "true", message: "请输入寄件负责人" }],
        enterpriseDirectorMobile: [{ validator: checkPhone, trigger: "blur" }],
      },
      isEdit: false,
      isNew: false,
      ruleForm: {
        warehouseName: "", //仓库名称
        totalCount: "", //货品总件数
        cebxsi: 0, //报关方式
        exportCity: "", //出口城市
        deliveryDate: "", //送货日期
        mailingMethod: 1, // 寄件方式（1为上门提货，2为自己送货）
        transportCategory: 1, // 配送方式（0为城配，1为1物流）
        ifAcceptOrder: 0, // 是否接受拼单（0为否，1为是）
        paymentMethod: 0, // 付款方式（0为月结，1为现付）
        remark: "", //备注（限200字）
        installationMethod: 0, // 安装方式（0为自己安装，1为预约安装）
        deliveryMethod: 0, // （0是无，1是自提，2为上门）// 送货方式
        customerName: "", // 收件名称
        customerAddress: "", // 收件人地址
        customerContactsPhone: "", // 收件人联系电话
        customerContacts: "", // 收件人姓名
        enterpriseName: "", // 寄件名称
        enterpriseAdress: "", // 寄件地址
        enterpriseDirector: "", // 寄件人名称
        enterpriseDirectorMobile: "", // 寄件人联系方式
        // refUrl: "", //反推地址
        // orgId: 1,
        // TenantId: 0,
        details: [],
      },
      waybill_num: "",
      visible: false,
      options: [],
    };
  },
  watch: {
    orderId: {
      handler(val) {
        this.getWaybill();
      },
      deep: true,
    },
  },
  computed: {},
  created() {
    this.getWaybill();
  },
  methods: {
    addSon() {
      this.visible = true;
    },
    add_success(list) {
      console.log("list",list);
      if (list && list.length>0) {
        list.forEach(e => {
          this.ruleForm.details.push(e);
        });
      }
    },
    del(idx) {
      this.ruleForm.details.splice(idx,1);
    },
    getWaybill() {
      getWaybill({order_id: this.orderId}).then(rst => {
        console.log("rst",rst);
        if (rst) {
          this.isEdit = false;
          this.ruleForm = rst;
          queryOrderWaybill({order_ids: [this.orderId]}).then(res => {
            console.log("res",res);
            this.waybill_num = res[0].waybill_num;
          }).catch(err => {
            this.$message.error(err.message);
          });
        } else this.isEdit = true;
      }).catch(err => {
        this.isEdit = true;
        this.ruleForm = {
          warehouseName: "", //仓库名称
          totalCount: "", //货品总件数
          cebxsi: 0, //报关方式
          exportCity: "", //出口城市
          deliveryDate: "", //送货日期
          mailingMethod: 1, // 寄件方式（1为上门提货，2为自己送货）
          transportCategory: 1, // 配送方式（0为城配，1为1物流）
          ifAcceptOrder: 0, // 是否接受拼单（0为否，1为是）
          paymentMethod: 0, // 付款方式（0为月结，1为现付）
          remark: "", //备注（限200字）
          installationMethod: 0, // 安装方式（0为自己安装，1为预约安装）
          deliveryMethod: 0, // （0是无，1是自提，2为上门）// 送货方式
          customerName: "", // 收件名称
          customerAddress: "", // 收件人地址
          customerContactsPhone: "", // 收件人联系电话
          customerContacts: "", // 收件人姓名
          enterpriseName: "", // 寄件名称
          enterpriseAdress: "", // 寄件地址
          enterpriseDirector: "", // 寄件人名称
          enterpriseDirectorMobile: "", // 寄件人联系方式
          // refUrl: "", //反推地址
          // orgId: 1,
          // TenantId: 0,
          details: [],
        };
        this.waybill_num = "";
        console.log(err)
      });
    },
    hide() {
      this.$emit("hide");
    },
    submitForm() {
      this.$refs.checkForm.validate((valid) => {
        if (valid) {
          this.submit();
        }
      });
    },
    submit() {
      if (this.ruleForm.ifAcceptOrder) {
        this.ruleForm.ifAcceptOrder = true;
      } else {
        this.ruleForm.ifAcceptOrder = false;
      }
      console.log("ruleForm", this.ruleForm);
      let obj = {
        waybill: this.ruleForm,
        order_id:this.orderId
      }
      createWaybill(obj).then(rst => {
          this.$emit("success");
          this.hide();
          this.$message.success("保存成功");
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
  },
};
</script>
<style lang="less">
.dialog-box {
  width: 94%;
  margin: 0 auto;
  .db-head {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    border-radius: 2px;
    padding: 0px 3px;
    border-bottom: 3px solid rgb(75, 128, 239);
  }
}
.dialog-table {
  width: 94%;
  margin: 0 auto;
}
</style>